<template>
  <div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refTable'].refresh()"
    >

      <template #buttons>

        <div class="mx-2">
          <div class="d-flex align-items-center">
            <div>
              <b-button
                href="#"
                variant="outline-info"
                size="sm"
                @click="openRecruitmentStatus"
              >
                Status Legend
              </b-button>
              <!-- <h1>{{ currentUser.role_id }}</h1> -->
            </div>
          </div>
        </div>

      </template>
      <template #table>
        <b-table
          slot="table"
          ref="refTable"
          no-provider-filtering
          :items="search"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(requester)="data">
            <span>
              <feather-icon
                icon="UserIcon"
                size="15"
                style="color: #0090E7"
              /> {{ data.item.requester }}
            </span>
          </template>
          <template #cell(new_employee_quantity)="data">
            <span>
              <feather-icon
                icon="UsersIcon"
                size="15"
                style="color: #0090E7"
              /> {{ data.item.count_started_working }} of  {{ data.item.new_employee_quantity }} employe(s)
            </span>
          </template>
          <template #cell(salary)="data">
            <span v-if="data.item.min_amount">
              S/.
              {{ data.item.min_amount | currency }} - S/.
              {{ data.item.max_amount | currency }}
            </span>
            <span v-else>
              To Consider
            </span>
          </template>
          <template #cell(shift)="data">
            <span :class="(data.item.shift === 'morning') ? 'text-primary' : (data.item.shift === 'afternoon') ? 'text-success' : 'text-info'">
              {{ data.item.shift.toUpperCase() }}
            </span>
          </template>
          <template #cell(status)="data">
            <b-row class="d-flex justify-content-center">
              <b-button
                style="border: none; border-radius:50px !important"
                :style="{ background: getStateColor(data.item.id_status) }"
                @click="openRecruitmentStatus"
              >
                <span
                  style="-webkit-text-stroke: 1px; color: black"
                  :style="(data.item.id_status === 4 || data.item.id_status === 6) && !isDarkSkin? 'color: white': ''"
                >
                  {{ data.item.status.toUpperCase() }}
                </span>
                <!-- <b-badge
                  v-if="data.item.id_status === 2
                    || data.item.id_status === 4"
                  variant="danger"
                  pill
                >
                  <feather-icon icon="BellIcon" />
                  <span class="sr-only">unread messages</span>
                </b-badge> -->
              </b-button>
              <tabler-icon
                icon="ListIcon"
                size="20"
                class="text-info cursor-pointer"
                style="margin-top:7px;margin-left:5px"
                @click="openModalStatusTracking(data.item)"
              />
            </b-row>
          </template>

          <template #cell(date_start)="data">
            <feather-icon
              icon="CalendarIcon"
              size="15"
              style="color: #0090E7"
            />
            {{ data.item.date_start | myGlobal }}
          </template>
          <template
            #cell(actions)="data"
          >
            <b-dropdown
              v-if="data.item.id_status===2"
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-b-tooltip.hover.left="'Approved Request Recruitment'"
                @click="modalApprovedByCeo(data.item)"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="text-success"
                />
                <span class="pl-1 text-success">APPROVED</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-b-tooltip.hover.left="'Rejected Request Recruitment'"
                @click="rejectedByCeo(data.item)"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="text-danger"
                />
                <span class="pl-1 text-danger">REJECTED</span>
              </b-dropdown-item>
            </b-dropdown>
            <div v-else>
              <feather-icon
                class="text-primary cursor-pointer"
                icon="UsersIcon"
                size="15"
                style="color: #0090E7"
                @click="openModalApplicants(data.item)"
              />
              <b-badge
                v-if="data.item.count_started_working>0 && data.item.id_status !==6"
                variant="success"
                pill
                style="margin-left:3px"
              >
                {{ data.item.count_started_working }}
              </b-badge>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-approved-by-ceo
      v-if="showModalApprovedByCeo"
      :recruitment="recruitmentData"
      @closeModalApprove="closeModalApprove"
      @refreshTable="$refs['refTable'].refresh()"
    />
    <modal-recruitment-status
      v-if="openModalRecruitmentStatus"
      @hidden="openModalRecruitmentStatus = false"
    />
    <modal-recruitment-applicants
      v-if="showModalApplicants"
      :recruitment="recruitmentData"
      @closeModalRecruimentApplicants="closeModalRecruimentApplicants"
      @refreshTable="refreshTable()"
    />
    <modal-recruitment-status-tracking
      v-if="recruitmentStatusTrackingModal"
      :recruitment="recruitmentData"
      @close="recruitmentStatusTrackingModal = false"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import filtersDataCeo from '@/views/commons/components/recruitment-process/data/filters.recruiment.data';
import Service from '@/views/commons/components/recruitment-process/services/recruiment.service';
import FieldsCeo from '@/views/commons/components/recruitment-process/data/fieldsCeo.recruitment.data';
import ModalApprovedByCeo from '@/views/commons/components/recruitment-process/components/modals/ModalApprovedByCeo.vue';
import ModalRecruitmentStatus from '@/views/commons/components/recruitment-process/components/modals/ModalStatusLegends.vue';
import ModalRecruitmentApplicants from '@/views/commons/components/recruitment-process/components/modals/ModalRecruimentAplicants.vue';
import ModalRecruitmentStatusTracking from '@/views/commons/components/recruitment-process/components/modals/ModalRecruitmentStatusTracking.vue';
import helpdeskService from '@/views/commons/components/helpdesk/services/helpdesk.service';

export default {
  components: {
    ModalApprovedByCeo,
    ModalRecruitmentStatus,
    ModalRecruitmentApplicants,
    ModalRecruitmentStatusTracking,
  },
  data() {
    return {

      openModalRecruitmentStatus: false,

      // GRILLA
      operation: 1,
      modal: false,
      holidaySelected: {},
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      sortBy: 'entry_date',
      sortDesc: true,
      fields: FieldsCeo,
      startPage: null,
      endPage: '',
      nextPage: '',
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Name of requester or job name...',
        model: '',
      },
      filter: filtersDataCeo,
      transactionId: null,
      newForm: {
        description: '',
        day: null,
        month: null,
        created_by: '',
        rpt: '1',
        type: 'Day Calendar',
      },
      showModalApprovedByCeo: false,
      recruitmentData: null,
      showModalApplicants: false,
      recruitmentStatusTrackingModal: false,
      recruitment_id: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    visibleFields() {
      return this.fields;
    },
  },
  mounted() {
    this.getModules();
    this.filter[3].visible = true;
  },
  methods: {
    openModalStatusTracking(recruitment) {
      this.recruitmentStatusTrackingModal = true;
      this.recruitmentData = recruitment;
    },
    openRecruitmentStatus() {
      this.openModalRecruitmentStatus = true;
    },

    refreshTable() {
      this.$refs.refTable.refresh();
    },

    async search(ctx) {
      try {
        const obj = {
          name_text: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sort_by: ctx.sortBy,
          sort: ctx.sortDesc === true ? 'DESC' : 'ASC',
          tab: this.$route.meta.tab,
          module_id: this.filter[3].model,
          status: this.filter[2].model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          shift: this.filter[4].model,

        };

        const res = await Service.getRecruitmentProcess(obj);
        if (res.status === 200) {
          this.startPage = res.data.from;
          this.paginate.currentPage = res.data.current_page;
          this.paginate.perPage = res.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = res.data.last_page;
          this.totalRows = res.data.total;
          this.toPage = res.data.to;
          return res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
      return [];
    },

    toTitleCase(inputString) {
      return inputString.toLowerCase().replace(/^(.)|\s(.)/g, match => match.toUpperCase());
    },
    closeModalRecruimentApplicants() {
      this.showModalApplicants = false;
    },
    openModalApplicants(recruitment) {
      this.showModalApplicants = true;
      this.recruitmentData = recruitment;
    },
    modalApprovedByCeo(recruitment) {
      this.showModalApprovedByCeo = true;
      this.recruitmentData = recruitment;
    },
    async rejectedByCeo(recruitment) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          id: recruitment.id,
          salary: null,
          status: 4,
          user_id: this.currentUser.user_id,
        };
        const data = await Service.approveRequestRecruitment(params);
        if (data.status === 200) {
          this.showSuccessSwal('Success', 'Request Personnel Rejected');
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    closeModalApprove() {
      this.showModalApprovedByCeo = false;
    },
    getStateColor(state) {
      switch (state) {
        case 1: // CREATED BY CHIEF
          return this.isDarkSkin ? '#ffe88d !important' : '#FFD700 !important';
        case 2: // SENT TO CEO
          return this.isDarkSkin ? '#9be5e5 !important' : '#00CED1 !important';
        case 3: // approved by ceo
          return this.isDarkSkin ? '#ffcd85 !important' : '#FFA500 !important';
        case 4: // rejected by ceo
          return this.isDarkSkin ? '#ca87e9 !important' : '#9400D3 !important';
        case 5: // started by rrhh
          return this.isDarkSkin ? '#9fe592 !important' : '#32CD32 !important';
        case 6: // closed by rrhh
          return this.isDarkSkin ? '#ff9473 !important' : '#FF0000 !important';
        default:
          return '';
      }
    },
    async getModules() {
      const response = await helpdeskService.getModules();
      if (response.status === 200) {
        this.filter[3].options = [{ name: 'Select a module', id: null }];
        this.filter[3].options = [
          ...this.filter[3].options,
          ...response.data,
        ];
      }
    },
  },
};
</script>
